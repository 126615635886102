import React from "react";

const Logo = ({ style }) => {
  return (
    <svg
      viewBox="0 0 40 40"
      version="1.1"
      style={{
        ...style
      }}
    >
      <path d="M20.098,0c9.122,0.086 17.727,7.014 19.538,16.168c1.324,6.689 -1.029,13.985 -6.011,18.631c-7.285,6.793 -19.729,7.075 -27.312,-0.001c-6.084,-5.677 -8.081,-15.303 -4.621,-22.972c3.097,-6.865 10.207,-11.652 17.889,-11.823c0.173,-0.002 0.345,-0.003 0.517,-0.003Zm-0.246,1.914c-8.335,0.078 -16.118,6.478 -17.699,14.738c-1.563,8.171 3.255,17.116 10.963,20.274c6.362,2.607 14.143,1.194 19.208,-3.528c5.434,-5.067 7.306,-13.64 4.272,-20.582c-2.82,-6.452 -9.513,-10.925 -16.744,-10.902Zm3.948,24.477l-6.658,0l0,-1.267l5.022,0l0,-4.277l-4.52,0l0,-1.258l4.52,0l0,-4.408l-5.151,0l0,-1.388l6.787,0l0,12.598Zm-8.578,0l-1.725,0l-2.73,-8.799l-0.356,-1.283l-0.316,1.275l-2.73,8.807l-1.571,0l3.872,-12.598l1.66,0l3.896,12.598Zm18.922,0l-2.981,0c-1.371,0 -2.391,-0.24 -3.057,-0.719c-0.667,-0.479 -1.168,-1.202 -1.503,-2.171c-0.335,-0.969 -0.502,-2.116 -0.502,-3.442c0,-1.255 0.177,-2.358 0.53,-3.307c0.354,-0.95 0.855,-1.681 1.503,-2.192c0.648,-0.511 1.658,-0.767 3.029,-0.767l2.981,0l0,12.598Zm-1.636,-11.21l-1.094,0c-1.107,0 -1.896,0.198 -2.369,0.593c-0.472,0.395 -0.81,0.942 -1.012,1.643c-0.203,0.701 -0.304,1.668 -0.304,2.902c0,1.207 0.119,2.135 0.356,2.784c0.238,0.65 0.578,1.149 1.021,1.498c0.443,0.349 1.169,0.523 2.179,0.523l1.223,0l0,-9.943Z" />
    </svg>
  );
};

export default Logo;
